@font-face {
  font-family: "Circular";
  font-weight: 400;
  src: url(assets/fonts/CircularXX-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Circular";
  font-weight: 400;
  font-style: italic;
  src: url(assets/fonts/CircularXX-Italic.otf) format("opentype");
}

@font-face {
  font-family: "Circular";
  font-weight: 450;
  src: url(assets/fonts/CircularXX-Book.otf) format("opentype");
}

@font-face {
  font-family: "Circular";
  font-weight: 450;
  font-style: italic;
  src: url(assets/fonts/CircularXX-BookItalic.otf) format("opentype");
}

@font-face {
  font-family: "Circular";
  font-weight: 500;
  src: url(assets/fonts/CircularXX-Medium.otf) format("opentype");
}
@font-face {
  font-family: "Circular";
  font-weight: 500;
  font-style: italic;
  src: url(assets/fonts/CircularXX-MediumItalic.otf) format("opentype");
}
@font-face {
  font-family: "Circular";
  font-weight: 700;
  src: url(assets/fonts/CircularXX-Bold.otf) format("opentype");
}

@font-face {
  font-family: "Circular";
  font-weight: 700;
  font-style: italic;
  src: url(assets/fonts/CircularXX-BoldItalic.otf) format("opentype");
}
